@font-face {
  font-family: 'LineIcons';
  src:  url('../fonts/LineIcons.eot?tc3uo0');
  src:  url('../fonts/LineIcons.eot?tc3uo0#iefix') format('embedded-opentype'),
    url('../fonts/LineIcons.ttf?tc3uo0') format('truetype'),
    url('../fonts/LineIcons.woff?tc3uo0') format('woff'),
    url('../fonts/LineIcons.svg?tc3uo0#LineIcons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="lni-"], [class*=" lni-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'LineIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lni-image:before {
  content: "\e991";
}
.lni-linkedin-filled:before {
  content: "\e9ac";
}
.lni-add-files:before {
  content: "\e900";
}
.lni-agenda:before {
  content: "\e901";
}
.lni-alarm-clock:before {
  content: "\e902";
}
.lni-alarm:before {
  content: "\e903";
}
.lni-amazon:before {
  content: "\e904";
}
.lni-amex:before {
  content: "\e905";
}
.lni-anchor:before {
  content: "\e906";
}
.lni-android:before {
  content: "\e907";
}
.lni-angle-double-down:before {
  content: "\e908";
}
.lni-angle-double-left:before {
  content: "\e909";
}
.lni-angle-double-right:before {
  content: "\e90a";
}
.lni-angle-double-up:before {
  content: "\e90b";
}
.lni-apartment:before {
  content: "\e90c";
}
.lni-apple:before {
  content: "\e90d";
}
.lni-archive:before {
  content: "\e90e";
}
.lni-arrow-down-circle:before {
  content: "\e90f";
}
.lni-arrow-down:before {
  content: "\e910";
}
.lni-arrow-left-circle:before {
  content: "\e911";
}
.lni-arrow-left:before {
  content: "\e912";
}
.lni-arrow-right-circle:before {
  content: "\e913";
}
.lni-arrow-right:before {
  content: "\e914";
}
.lni-arrow-top-left:before {
  content: "\e915";
}
.lni-arrow-top-right:before {
  content: "\e916";
}
.lni-arrow-up-circle:before {
  content: "\e917";
}
.lni-arrow-up:before {
  content: "\e918";
}
.lni-arrows-horizontal:before {
  content: "\e919";
}
.lni-arrows-vertical:before {
  content: "\e91a";
}
.lni-ban:before {
  content: "\e91b";
}
.lni-bar-chart:before {
  content: "\e91c";
}
.lni-basketball:before {
  content: "\e91d";
}
.lni-behance:before {
  content: "\e91e";
}
.lni-bi-cycle:before {
  content: "\e91f";
}
.lni-bitbucket:before {
  content: "\e920";
}
.lni-bitcoin:before {
  content: "\e921";
}
.lni-blackboard:before {
  content: "\e922";
}
.lni-bluetooth:before {
  content: "\e923";
}
.lni-bold:before {
  content: "\e924";
}
.lni-bolt-alt:before {
  content: "\e925";
}
.lni-bolt:before {
  content: "\e926";
}
.lni-book:before {
  content: "\e927";
}
.lni-bookmark-alt:before {
  content: "\e928";
}
.lni-bookmark:before {
  content: "\e929";
}
.lni-briefcase:before {
  content: "\e92a";
}
.lni-brush-alt:before {
  content: "\e92b";
}
.lni-brush:before {
  content: "\e92c";
}
.lni-bubble:before {
  content: "\e92d";
}
.lni-bug:before {
  content: "\e92e";
}
.lni-bulb:before {
  content: "\e92f";
}
.lni-bullhorn:before {
  content: "\e930";
}
.lni-bus:before {
  content: "\e931";
}
.lni-calendar:before {
  content: "\e932";
}
.lni-camera:before {
  content: "\e933";
}
.lni-car:before {
  content: "\e934";
}
.lni-cart-full:before {
  content: "\e935";
}
.lni-cart:before {
  content: "\e936";
}
.lni-check-box:before {
  content: "\e937";
}
.lni-check-mark-circle:before {
  content: "\e938";
}
.lni-chevron-down-circle:before {
  content: "\e939";
}
.lni-chevron-down:before {
  content: "\e93a";
}
.lni-chevron-left-circle:before {
  content: "\e93b";
}
.lni-chevron-left:before {
  content: "\e93c";
}
.lni-chevron-right-circle:before {
  content: "\e93d";
}
.lni-chevron-right:before {
  content: "\e93e";
}
.lni-chevron-up-circle:before {
  content: "\e93f";
}
.lni-chevron-up:before {
  content: "\e940";
}
.lni-chrome:before {
  content: "\e941";
}
.lni-circle-minus:before {
  content: "\e942";
}
.lni-clipboard:before {
  content: "\e943";
}
.lni-close:before {
  content: "\e944";
}
.lni-cloud-check:before {
  content: "\e945";
}
.lni-cloud-download:before {
  content: "\e946";
}
.lni-cloud-sync:before {
  content: "\e947";
}
.lni-cloud-upload:before {
  content: "\e948";
}
.lni-cloud:before {
  content: "\e949";
}
.lni-code:before {
  content: "\e94a";
}
.lni-coffee-cup:before {
  content: "\e94b";
}
.lni-cog:before {
  content: "\e94c";
}
.lni-color-pallet:before {
  content: "\e94d";
}
.lni-comment-reply-alt:before {
  content: "\e94e";
}
.lni-comments-alt:before {
  content: "\e94f";
}
.lni-comments:before {
  content: "\e950";
}
.lni-construction:before {
  content: "\e951";
}
.lni-control-panel:before {
  content: "\e952";
}
.lni-crop:before {
  content: "\e953";
}
.lni-cross-circle:before {
  content: "\e954";
}
.lni-crown:before {
  content: "\e955";
}
.lni-css3:before {
  content: "\e956";
}
.lni-cup:before {
  content: "\e957";
}
.lni-cut:before {
  content: "\e958";
}
.lni-dashboard:before {
  content: "\e959";
}
.lni-database:before {
  content: "\e95a";
}
.lni-dinner:before {
  content: "\e95b";
}
.lni-direction-alt:before {
  content: "\e95c";
}
.lni-direction-ltr:before {
  content: "\e95d";
}
.lni-direction-rtl:before {
  content: "\e95e";
}
.lni-direction:before {
  content: "\e95f";
}
.lni-display:before {
  content: "\e960";
}
.lni-download:before {
  content: "\e961";
}
.lni-drop:before {
  content: "\e962";
}
.lni-dropbox:before {
  content: "\e963";
}
.lni-drupal:before {
  content: "\e964";
}
.lni-emoji-neutral:before {
  content: "\e965";
}
.lni-emoji-sad:before {
  content: "\e966";
}
.lni-emoji-smile:before {
  content: "\e967";
}
.lni-empty-file:before {
  content: "\e968";
}
.lni-enter:before {
  content: "\e969";
}
.lni-envelope:before {
  content: "\e96a";
}
.lni-eraser:before {
  content: "\e96b";
}
.lni-exit-down:before {
  content: "\e96c";
}
.lni-exit-up:before {
  content: "\e96d";
}
.lni-exit:before {
  content: "\e96e";
}
.lni-eye:before {
  content: "\e96f";
}
.lni-facebook-filled:before {
  content: "\e970";
}
.lni-facebook:before {
  content: "\e971";
}
.lni-files:before {
  content: "\e972";
}
.lni-film-play:before {
  content: "\e973";
}
.lni-firefox:before {
  content: "\e974";
}
.lni-flag-alt:before {
  content: "\e975";
}
.lni-flag-cubic:before {
  content: "\e976";
}
.lni-flag:before {
  content: "\e977";
}
.lni-folder:before {
  content: "\e978";
}
.lni-frame-contract:before {
  content: "\e979";
}
.lni-frame-expand:before {
  content: "\e97a";
}
.lni-full-screen:before {
  content: "\e97b";
}
.lni-funnel:before {
  content: "\e97c";
}
.lni-gallery:before {
  content: "\e97d";
}
.lni-game:before {
  content: "\e97e";
}
.lni-gift:before {
  content: "\e97f";
}
.lni-git:before {
  content: "\e980";
}
.lni-github:before {
  content: "\e981";
}
.lni-google-plus:before {
  content: "\e982";
}
.lni-graduation:before {
  content: "\e983";
}
.lni-grid-alt:before {
  content: "\e984";
}
.lni-grid:before {
  content: "\e985";
}
.lni-hammer:before {
  content: "\e986";
}
.lni-hand:before {
  content: "\e987";
}
.lni-harddrive:before {
  content: "\e988";
}
.lni-headphone-alt:before {
  content: "\e989";
}
.lni-headphone:before {
  content: "\e98a";
}
.lni-heart-filled:before {
  content: "\e98b";
}
.lni-heart-pulse:before {
  content: "\e98c";
}
.lni-heart:before {
  content: "\e98d";
}
.lni-help:before {
  content: "\e98e";
}
.lni-highlight-alt:before {
  content: "\e98f";
}
.lni-highlight:before {
  content: "\e990";
}
.lni-hobile:before {
  content: "\e992";
}
.lni-home:before {
  content: "\e993";
}
.lni-hourglass:before {
  content: "\e994";
}
.lni-html5:before {
  content: "\e995";
}
.lni-inbox:before {
  content: "\e996";
}
.lni-indent-decrease:before {
  content: "\e997";
}
.lni-indent-increase:before {
  content: "\e998";
}
.lni-Infinite:before {
  content: "\e999";
}
.lni-information:before {
  content: "\e99a";
}
.lni-instagram-filled:before {
  content: "\e99b";
}
.lni-instagram:before {
  content: "\e99c";
}
.lni-italic:before {
  content: "\e99d";
}
.lni-joomla:before {
  content: "\e99e";
}
.lni-key:before {
  content: "\e99f";
}
.lni-keyboard:before {
  content: "\e9a0";
}
.lni-laptop-phone:before {
  content: "\e9a1";
}
.lni-laptop:before {
  content: "\e9a2";
}
.lni-layers:before {
  content: "\e9a3";
}
.lni-layout:before {
  content: "\e9a4";
}
.lni-leaf:before {
  content: "\e9a5";
}
.lni-licencse:before {
  content: "\e9a6";
}
.lni-line-dashed:before {
  content: "\e9a7";
}
.lni-line-dotted:before {
  content: "\e9a8";
}
.lni-line-double:before {
  content: "\e9a9";
}
.lni-line-spacing:before {
  content: "\e9aa";
}
.lni-link:before {
  content: "\e9ab";
}
.lni-linkedin:before {
  content: "\e9ad";
}
.lni-list:before {
  content: "\e9ae";
}
.lni-lock:before {
  content: "\e9af";
}
.lni-magnet:before {
  content: "\e9b0";
}
.lni-magnifier:before {
  content: "\e9b1";
}
.lni-map-marker:before {
  content: "\e9b2";
}
.lni-map:before {
  content: "\e9b3";
}
.lni-mastercard:before {
  content: "\e9b4";
}
.lni-medall-alt:before {
  content: "\e9b5";
}
.lni-medall:before {
  content: "\e9b6";
}
.lni-medium:before {
  content: "\e9b7";
}
.lni-menu-circle:before {
  content: "\e9b8";
}
.lni-menu:before {
  content: "\e9b9";
}
.lni-mic:before {
  content: "\e9ba";
}
.lni-microphone:before {
  content: "\e9bb";
}
.lni-minus:before {
  content: "\e9bc";
}
.lni-mobile:before {
  content: "\e9bd";
}
.lni-more-alt:before {
  content: "\e9be";
}
.lni-more:before {
  content: "\e9bf";
}
.lni-mouse:before {
  content: "\e9c0";
}
.lni-move:before {
  content: "\e9c1";
}
.lni-music:before {
  content: "\e9c2";
}
.lni-notepad:before {
  content: "\e9c3";
}
.lni-package:before {
  content: "\e9c4";
}
.lni-page-break:before {
  content: "\e9c5";
}
.lni-paint-bucket:before {
  content: "\e9c6";
}
.lni-paint-roller:before {
  content: "\e9c7";
}
.lni-paperclip:before {
  content: "\e9c8";
}
.lni-paypal:before {
  content: "\e9c9";
}
.lni-pencil-alt:before {
  content: "\e9ca";
}
.lni-pencil:before {
  content: "\e9cb";
}
.lni-phone-handset:before {
  content: "\e9cc";
}
.lni-phone:before {
  content: "\e9cd";
}
.lni-pie-chart:before {
  content: "\e9ce";
}
.lni-pilcrow:before {
  content: "\e9cf";
}
.lni-pin-alt:before {
  content: "\e9d0";
}
.lni-pin-corner:before {
  content: "\e9d1";
}
.lni-pin:before {
  content: "\e9d2";
}
.lni-pinterest:before {
  content: "\e9d3";
}
.lni-playstore:before {
  content: "\e9d4";
}
.lni-plug:before {
  content: "\e9d5";
}
.lni-plus:before {
  content: "\e9d6";
}
.lni-pointer-down:before {
  content: "\e9d7";
}
.lni-pointer-left:before {
  content: "\e9d8";
}
.lni-pointer-right:before {
  content: "\e9d9";
}
.lni-pointer-up:before {
  content: "\e9da";
}
.lni-pointer:before {
  content: "\e9db";
}
.lni-power-switch:before {
  content: "\e9dc";
}
.lni-printer:before {
  content: "\e9dd";
}
.lni-pulse:before {
  content: "\e9de";
}
.lni-question-circle:before {
  content: "\e9df";
}
.lni-quora:before {
  content: "\e9e0";
}
.lni-reddit:before {
  content: "\e9e1";
}
.lni-reload:before {
  content: "\e9e2";
}
.lni-reply:before {
  content: "\e9e3";
}
.lni-rocket:before {
  content: "\e9e4";
}
.lni-rss-feed:before {
  content: "\e9e5";
}
.lni-ruler-alt:before {
  content: "\e9e6";
}
.lni-ruler-pencil:before {
  content: "\e9e7";
}
.lni-ruler:before {
  content: "\e9e8";
}
.lni-save-alt:before {
  content: "\e9e9";
}
.lni-save:before {
  content: "\e9ea";
}
.lni-search:before {
  content: "\e9eb";
}
.lni-select:before {
  content: "\e9ec";
}
.lni-share-alt:before {
  content: "\e9ed";
}
.lni-share:before {
  content: "\e9ee";
}
.lni-shield:before {
  content: "\e9ef";
}
.lni-shift-left:before {
  content: "\e9f0";
}
.lni-shift-right:before {
  content: "\e9f1";
}
.lni-shortcode:before {
  content: "\e9f2";
}
.lni-signal:before {
  content: "\e9f3";
}
.lni-slice:before {
  content: "\e9f4";
}
.lni-sort-alpha-asc:before {
  content: "\e9f5";
}
.lni-sort-amount-asc:before {
  content: "\e9f6";
}
.lni-soundcloud:before {
  content: "\e9f7";
}
.lni-spellcheck:before {
  content: "\e9f8";
}
.lni-spotify:before {
  content: "\e9f9";
}
.lni-spray:before {
  content: "\e9fa";
}
.lni-stamp:before {
  content: "\e9fb";
}
.lni-star-empty:before {
  content: "\e9fc";
}
.lni-star-filled:before {
  content: "\e9fd";
}
.lni-star-half:before {
  content: "\e9fe";
}
.lni-star:before {
  content: "\e9ff";
}
.lni-stats-down:before {
  content: "\ea00";
}
.lni-stats-up:before {
  content: "\ea01";
}
.lni-strikethrough:before {
  content: "\ea02";
}
.lni-stripe:before {
  content: "\ea03";
}
.lni-support:before {
  content: "\ea04";
}
.lni-tab:before {
  content: "\ea05";
}
.lni-tag:before {
  content: "\ea06";
}
.lni-target:before {
  content: "\ea07";
}
.lni-teabag:before {
  content: "\ea08";
}
.lni-text-align-right:before {
  content: "\ea09";
}
.lni-text-align-center:before {
  content: "\ea0a";
}
.lni-text-align-justify:before {
  content: "\ea0b";
}
.lni-text-align-left:before {
  content: "\ea0c";
}
.lni-text-format-remove:before {
  content: "\ea0d";
}
.lni-text-format:before {
  content: "\ea0e";
}
.lni-text-size:before {
  content: "\ea0f";
}
.lni-thought:before {
  content: "\ea10";
}
.lni-thumbs-down:before {
  content: "\ea11";
}
.lni-thumbs-up:before {
  content: "\ea12";
}
.lni-ticket-alt:before {
  content: "\ea13";
}
.lni-ticket:before {
  content: "\ea14";
}
.lni-timer:before {
  content: "\ea15";
}
.lni-train:before {
  content: "\ea16";
}
.lni-trash:before {
  content: "\ea17";
}
.lni-tshirt:before {
  content: "\ea18";
}
.lni-twitter-filled:before {
  content: "\ea19";
}
.lni-twitter:before {
  content: "\ea1a";
}
.lni-underline:before {
  content: "\ea1b";
}
.lni-unlink:before {
  content: "\ea1c";
}
.lni-unlock:before {
  content: "\ea1d";
}
.lni-upload:before {
  content: "\ea1e";
}
.lni-user:before {
  content: "\ea1f";
}
.lni-users:before {
  content: "\ea20";
}
.lni-vector:before {
  content: "\ea21";
}
.lni-video:before {
  content: "\ea22";
}
.lni-visa:before {
  content: "\ea23";
}
.lni-volume-high:before {
  content: "\ea24";
}
.lni-volume-low:before {
  content: "\ea25";
}
.lni-volume-medium:before {
  content: "\ea26";
}
.lni-volume-mute:before {
  content: "\ea27";
}
.lni-wallet:before {
  content: "\ea28";
}
.lni-warning:before {
  content: "\ea29";
}
.lni-wheelchair:before {
  content: "\ea2a";
}
.lni-wordpress-filled:before {
  content: "\ea2b";
}
.lni-wordpress:before {
  content: "\ea2c";
}
.lni-world-alt:before {
  content: "\ea2d";
}
.lni-world:before {
  content: "\ea2e";
}
.lni-write:before {
  content: "\ea2f";
}
.lni-zip:before {
  content: "\ea30";
}
.lni-zoom-in:before {
  content: "\ea31";
}
.lni-zoom-out:before {
  content: "\ea32";
}
